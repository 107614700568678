<template>
  <center>
    <h1>Deployment Extended!</h1>
    <p>
      <a href="/"><span class="underline">cloud page</span></a>
    </p>
  </center>
</template>

<script>
import requests from '@/requests/requests.js'

export default {
  name: 'NotFound',
  props: {
    id: Number, 
  },
  computed: {
  }, 
  created() {
    // TODO -- move all this to cloud page (make a success modal!)
    setTimeout(() => { 
      requests.postSuccess(this.id).then((response) => response.json()).then((body) => {
        return   
      })
    }, 10);
  }
}
</script>

<style scoped>

  center {
    margin: 15vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }

</style>
