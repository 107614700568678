<template>
  <div class="raw-code">
    <div v-if="filename">
<pre style="word-wrap: break-word; white-space: pre-wrap;">
{{displayFile}}
</pre>
    </div>
    <div v-else>
      oops, no file
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filename: String
  },
  data: function() {
    return {
      displayFile: String
    };
  },
  created() {
    //this.$nextTick(() => {
      let file = window.localStorage.getItem(this.filename)
      this.displayFile = file
 //   })
  }
}
</script>

<style>

body {
  margin-top: -3%;
  margin-left: 1%;
}

pre {
  display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1em 0px;
  text-align: left;
}

</style>
